@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");


.kyc_container_div {
display: flex;
width: 100%;
justify-content: center;
}
.kycform_div {
    width: 60rem;
    padding: 2rem;
    margin-left: -7rem;
    margin-bottom: 5rem;
    background-color: whitesmoke;
    border-radius: 1rem;
}
.input_div {
    border: none;
    border-radius: 1rem;
    background-color: #fff;
}

.is_name_div {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 1.2rem;
}
.btn-div {
    margin-top: 2rem;
    width: 5rem;
}
.btn_div {
    margin-top: 2rem;
    margin-left: 3rem;
    width: 5rem;
    border: none;
    border-radius: 1rem;
}
@media screen and (min-width: 769px) and (max-width: 991px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .kycform_div {
      width: 40rem;
      margin-left: 10rem;
}
.is_name_div {
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 1.2rem;
}
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .kycform_div {
        width: 50rem;
        margin-left: 10rem;
  }
}
@media screen and (min-width: 375px) and (max-width: 768px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15rem;
    }
    .kycform_div {
        width: 22rem;
        margin-top: 3rem;
        margin-left: 15rem;
  }
}
@media screen and (width: 768px) {
    .kyc_container_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15rem;
    }
    .kycform_div {
        width: 35rem;
        margin-top: 3rem;
        margin-left: 15rem;
  }
}