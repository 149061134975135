.navbar-light .navbar-toggler {
    color: rgb(0 0 0);
    border-color: rgb(0 0 0 / 0%);
    outline: none;
    left: 10px;
    margin-right: 2rem;
}
/* .nav_div {
    display: flex;
    height: 30rem;
} */

.nav_mobile_div {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}