
.up-nav {
    font-size: 1.2rem;
    text-decoration: none;
    border: none;
    color: #fff;
}
.up-nav:hover {
    text-decoration: none;
    color: black;
}
.in-nav {
font-size: 1.2rem;
text-decoration: none;
border: none;
color: #fff;
}
.in-nav:hover {
    text-decoration: none;
    color: black;
}